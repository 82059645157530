import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {navigate} from 'gatsby'
import {Wrapper, MagazineWrapper, ContentWrapper} from './Magazines.styles'
import ContentContainer from '../ContentContainer'
import Magazine from '../Magazine'
import Pagination from '../Pagination'
import routes from '../../config/routes'
import Text from '../Text'

const Magazines = ({data, textData, pageContext, children, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <ContentWrapper>
          <Text content={textData.section_title[language]} size="title" mb={10} />
          <Text content={textData.section_description[language]} size="md" mb={40} />
          {data &&
            data.edges.map(i => (
              <MagazineWrapper>
                <Magazine
                  imgSrc={i.node.image.url}
                  date={i.node.date[language]}
                  title={i.node.title[language]}
                  subtitle={i.node.subtitle[language]}
                  fileUrl={i.node.file.url}
                />
              </MagazineWrapper>
            ))}
          <Pagination
            onPageChange={({selected}) =>
              navigate(routes.kmagazin({page: selected + 1}, {language}))
            }
            pageCount={pageContext.numberOfPages}
            initialPage={pageContext.pageNumber}
          />
        </ContentWrapper>
      </ContentContainer>
    </Wrapper>
  )
}

Magazines.propTypes = {}
Magazines.defaultProps = {}

export default Magazines
