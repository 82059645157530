import styled from 'styled-components'

export const Wrapper = styled.div`
  ${({theme}) => theme.breakpoints.down('tabletS')} {
    margin: 0 -15px;
  }
`

export const WrapperInner = styled.div`
  display: flex;
  background: ${({theme}) => theme.palette.common.white};
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.0964543);

  ${({theme}) => theme.breakpoints.down('tabletS')} {
    margin: 0 auto;
    max-width: 375px;
    width: 100%;
    flex-direction: column;
  }
`

export const ImageWrapperOuter = styled.div`
  max-width: 283px;
  width: 100%;

  ${({theme}) => theme.breakpoints.down('tabletS')} {
    max-width: 375px;
  }
`
export const ImageWrapper = styled.div`
  padding-bottom: 100%;
  position: relative;
`
export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const TextWrapper = styled.div`
  width: 100%;
  padding: 27px 53px 27px 67px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ${({theme}) => theme.breakpoints.down('tablet')} {
    padding: 15px 15px 15px 40px;
  }

  ${({theme}) => theme.breakpoints.down('tabletS')} {
    padding: 50px 30px 30px 30px;
  }
`

export const TextWrapperTop = styled.div`
  width: 100%;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 35px;
  max-width: 204px;

  ${({theme}) => theme.breakpoints.down('tabletS')} {
    margin: 0 auto;
    margin-top: 30px;
  }
`
