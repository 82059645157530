import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {
  Image,
  ImageWrapper,
  ImageWrapperOuter,
  Wrapper,
  TextWrapper,
  TextWrapperTop,
  ButtonWrapper,
  WrapperInner,
} from './Magazine.styles'
import Text from '../Text'
import Button from '../Button'

const Magazine = ({imgSrc, date, title, subtitle, fileUrl, ...rest}) => {
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <WrapperInner>
        <ImageWrapperOuter>
          <ImageWrapper>{imgSrc && <Image src={imgSrc} />}</ImageWrapper>
        </ImageWrapperOuter>
        <TextWrapper>
          <TextWrapperTop>
            {date && <Text content={date} mb={11} />}
            {title && <Text content={title} mb={13} size="titleXs" />}
            {subtitle && <Text content={subtitle} mb={0} />}
          </TextWrapperTop>
          <ButtonWrapper>
            <Button
              target="_blank"
              href={fileUrl || undefined}
              text={t('kmagazin.btnDownload')}
              w100
              variant="green"
              uppercase
            />
          </ButtonWrapper>
        </TextWrapper>
      </WrapperInner>
    </Wrapper>
  )
}

Magazine.propTypes = {}
Magazine.defaultProps = {}

export default Magazine
