import React, {useEffect} from 'react'
import SEO from 'components/SEO'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import HeroWithoutSlider from '../components/HeroWithoutSlider'
import GeneralContacts from '../components/GeneralContacts'
import ShowMob from '../components/ShowMob'
import Header from '../components/Header'
import BlogBlockSection from '../sections/BlogBlockSection'
import Magazines from '../components/Magazines'
import Layout from "../components/Layout";

export default function Kmagazin({
  data,
  location,
  pageContext,
  pageContext: {
    breadcrumb: {crumbs},
  },
}) {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiMagazinePage.en_disabled) {
      changeLanguage('de')
    }
  }, [])

  return (
    <Layout dataLocales={data} location={location}>
      <Header />
      <SEO
        title={data.strapiMagazinePage.seo_page_title[language]}
        description={data.strapiMagazinePage.seo_page_description[language]}
      />
      <HeroWithoutSlider
        title={data.strapiMagazinePage.title[language]}
        subtitle={data.strapiMagazinePage.description[language]}
        crumbs={crumbs}
      />
      <Magazines
        data={data.allStrapiMagazine}
        textData={data.strapiMagazinePage}
        pageContext={pageContext}
      />
      <BlogBlockSection
        data={data.allStrapiBlog.edges}
        title={t('sections.blog.title')}
        subtitle={t('sections.blog.subtitle')}
        location={location}
      />
      <ShowMob>
        <GeneralContacts />
      </ShowMob>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    allStrapiMagazine(skip: $skip, limit: $limit, sort: {order: ASC, fields: order}) {
      edges {
        node {
          date {
            de
            en
            id
          }
          order
          title {
            de
            en
            id
          }
          subtitle {
            de
            en
            id
          }
          image {
            url
          }
          published_at
          file {
            url
          }
        }
      }
    }
    allStrapiBlog(sort: {order: DESC, fields: date_for_sort}) {
      edges {
        node {
          article {
            de
            en
          }
          date {
            de
            en
          }
          description_short {
            de
            en
          }
          navigation {
            de
            en
          }
          reading_time {
            de
            en
          }
          page_url
          date_for_sort
          published_at
          title {
            de
            en
          }
        }
      }
    }
    strapiMagazinePage {
      en_disabled
      seo_page_description {
        de
        en
      }
      seo_page_title {
        de
        en
      }
      description {
        de
        en
      }
      title {
        de
        en
      }
      section_title {
        de
        en
      }
      section_description {
        de
        en
      }
    }
  }
`
