import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 45px;
  background: ${({theme}) => theme.palette.primary.bg};
`

export const ContentWrapper = styled.div`
  max-width: 770px;
  width: 100%;
`
export const MagazineWrapper = styled.div`
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
`
